export default {
  series: [
    "Are you with me?",
    "Aren't you forgetting something?",
    "But then again, I...",
    "Can I talk to you for a minute?",
    "Can I ask you a favor?",
    "Count me in",
    "Can't argue with that.",
    "Dinner's ready!",
    "Don't be a baby.",
    "Don't talk with your mouth full.",
    "Don't change the subject.",
    "Do you mind if I join you?",
    "Do I have to?",
    "Don't stay up too late.",
    "Don't play dumb with me.",
    "Do you regret it?",
    "Easier said than done",
    "Fire in the hole!",
    "Fancy meeting you here.",
    "Get out of my way!",
    "How did you do that?",
    "How should I know?",
    "Hold your fire!",
    "I deserve it.",
    "I can't let that happen.",
    "I'll walk you out.",
    "I'm used to it",
    "I'll be right there.",
    "I can't image why.",
    "I'm not armed.",
    "I have no idea.",
    "I'll take you home.",
    "It's all your fault.",
    "I love you with all my heart.",
    "I need a miracle.",
    "I'm exhausted.",
    "I'll give it a shot.",
    "Is everything okay?",
    "It's none of your business",
    "I'm working.",
    "I'll keep that in mind.",
    "I didn't do anything wrong.",
    "I have an idea.",
    "I have no friends.",
    "It's good to be home.",
    "I need a break.",
    "I was a fool.",
    "I want my money back.",
    "I've heard so much about you.",
    "I feel sick.",
    "I think so.",
    "I'll give you a hint.",
    "I'll think about it.",
    "I hate you.",
    "I forgive you.",
    "It won't happen again.",
    "Just in time.",
    "Just tell me.",
    "Keep it up.",
    "Let's move on.",
    "May I speak frankly?",
    "Make up your mind.",
    "Mind your own business.",
    "No news is good news.",
    "No worries.",
    "No problem.",
    "Pardon my French.",
    "Something like that.",
    "See you in the morning.",
    "Stop complaining.",
    "Snap out of it.",
    "Say no more.",
    "This is scary.",
    "Thank you for seeing me.",
    "The more, the merrier.",
    "Time flies when you're having fun.",
    "That is so nice of you.",
    "Take care of yourself.",
    "This is all my fault.",
    "What do you mean?",
    "What else do you want?",
    "What brings you here?",
    "What a coincidence.",
    "What do you want from me?",
    "Where are you?",
    "What can I do for you.",
    "What have you benn up to?",
    "Want to bet?",
    "Will do.",
    "What do you wanna do?",
    "What have I done?",
    "What's that sound?",
    "What are you looking at?",
    "Will you go out with me?",
    "What is your problem?",
    "What's your favorite city?",
    "What a shame.",
    "Will you marry me?",
    "What kind of work do you do?",
    "What the hell is that?",
    "We must do something.",
    "You look nice.",
    "You're under arrest.",
    "You set me up.",
    "Your wish is my command.",
    "You have my word.",
    "You got a promotion?",
    "You can't buy me.",
    "You got a light?",
    "You don't scare me."
  ]
};
