var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"user-banner banner",style:(_vm.cover)},[_c('h1',{staticClass:"banner-title"},[_vm._v(_vm._s(_vm.$t("navBar.personal")))])]),_c('v-card',{staticClass:"blog-container"},[_c('div',[_c('span',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.$t("personal.profile")))])]),_c('v-row',{staticClass:"info-wrapper"},[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('button',{attrs:{"id":"pick-avatar"}},[_c('v-avatar',{attrs:{"size":"140"}},[_c('img',{attrs:{"src":_vm.avatar,"alt":""}})])],1),_c('avatar-cropper',{attrs:{"trigger":"#pick-avatar","upload-url":"/api/user/avatar","labels":{
            submit: _vm.$t('button.save'),
            cancel: _vm.$t('button.no')
          },"upload-headers":{
            Lang: this.$i18n.locale,
            Token: this.$store.state.token
          }},on:{"changed":_vm.beforeUpload,"uploaded":_vm.uploadedAvatar}})],1),_c('v-col',{attrs:{"md":"7","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('email.username'),"disabled":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":_vm.$t('personal.nickname'),"maxlength":"50","placeholder":_vm.$t('personal.inputNickname'),"clearable":""},model:{value:(_vm.userForm.nickname),callback:function ($$v) {_vm.$set(_vm.userForm, "nickname", $$v)},expression:"userForm.nickname"}}),_c('v-text-field',{staticClass:"mt-7",attrs:{"label":_vm.$t('personal.intro'),"maxlength":"50","placeholder":_vm.$t('personal.inputIntro'),"clearable":""},model:{value:(_vm.userForm.intro),callback:function ($$v) {_vm.$set(_vm.userForm, "intro", $$v)},expression:"userForm.intro"}}),_c('v-text-field',{staticClass:"mt-7",attrs:{"label":_vm.$t('personal.website'),"maxlength":"255","placeholder":"https://","clearable":""},model:{value:(_vm.userForm.website),callback:function ($$v) {_vm.$set(_vm.userForm, "website", $$v)},expression:"userForm.website"}}),_c('div',{staticClass:"mt-7 binding"},[_c('v-text-field',{attrs:{"label":_vm.$t('personal.email'),"placeholder":_vm.$t('personal.bindEmail'),"disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.email)?_c('v-btn',{attrs:{"outlined":"","color":"primary","small":""},on:{"click":_vm.openEmailModel}},[_vm._v(" "+_vm._s(_vm.$t("button.change"))+" ")]):_c('v-btn',{attrs:{"outlined":"","color":"primary","small":""},on:{"click":_vm.openEmailModel}},[_vm._v(" "+_vm._s(_vm.$t("button.bind"))+" ")])],1),_c('v-btn',{staticClass:"mt-5",staticStyle:{"left":"45%"},attrs:{"outlined":""},on:{"click":_vm.updateInfo}},[_vm._v(_vm._s(_vm.$t("button.edit")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }